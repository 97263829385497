// import React from "react";
// import { useShoppingCart } from "./Context/ShoppingCartContextAll";
// import storeItems from "../data/store.json";
// import { Button, Stack } from "react-bootstrap";
// import formatCurrency from "../utils/formatCurrency";

// type CartItemProps = {
//   id: number;
//   quantity: number;
// };
// export default function CartItem({ id, quantity }: CartItemProps) {
//   const { removeFromCart } = useShoppingCart();
//   const item = storeItems.find((i) => i.id === id);
//   if (item == null) return null;
//   return (
//     <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
//       <img
//         src={item.image}
//         style={{ width: "125px", height: "75px", objectFit: "cover" }}
//         alt=""
//       />
//       <div className="me-auto">
//         <div>
//           {item.title}{" "}
//           {quantity > 1 && (
//             <span className="text-muted" style={{ fontSize: ".65rem" }}>
//               x{quantity}
//             </span>
//           )}
//         </div>
//         <div className="text-muted" style={{ fontSize: "75rem" }}>
//           {formatCurrency(item.price)}
//         </div>
//       </div>
//       <div>{formatCurrency(item.price * quantity)}</div>
//       <Button
//         variant="outline-danger"
//         size="sm"
//         onClick={() => removeFromCart(item.id)}
//       >
//         &times;
//       </Button>
//     </Stack>
//   );
// }







// 
import React from "react";
import { useShoppingCart } from "./Context/ShoppingCartContextAll";
import itemtest from "../data/itemtest.json";
import { Button, Stack } from "react-bootstrap";
import formatCurrency from "../utils/formatCurrency";

type CartItemProps = {
  id: number;
  quantity: number;
};
export default function CartItem({ id, quantity }: CartItemProps) {
  const { removeFromCart } = useShoppingCart();
  const item = itemtest.find((i) => i.id === id);
  if (item == null) return null;
  return (
    <Stack direction="horizontal" gap={2} className="d-flex align-items-center">
      <img
        src={item.imgUrl}
        style={{ width: "125px", height: "75px", objectFit: "cover" }}
        alt=""
      />
      <div className="me-auto">
        <div>
          {item.name}{" "}
          {quantity > 1 && (
            <span className="text-muted" style={{ fontSize: ".65rem" }}>
              x{quantity}
            </span>
          )}
        </div>
        <div className="text-muted" style={{ fontSize: "75rem" }}>
          {formatCurrency(item.price)}
        </div>
      </div>
      <div>{formatCurrency(item.price * quantity)}</div>
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => removeFromCart(item.id)}
      >
        &times;
      </Button>
    </Stack>
  );
}
