import React from "react";

import NavBar from "../component/NavBar";
import Footer from "../component/Footer";
import LogoBanner from "../component/LogoBanner";
import styled from "./AboutUs.module.css";
export default function AboutUs() {
  return (
    <>
      <NavBar />
      <div>
        <LogoBanner />
        <div>
          <div className={styled.About}>
            <h2>درباره ما</h2>
            <p>
              اکنون با ورود تکنولوژی اینترنت اشیا و تبدیل شدن آن به بخش جدایی
              ناپذیر زندگی، نوبتی هم باشد، نوبت خانه و ساختمان شماست که با
              تکنولوژی روز دنیا همگام شود. شرکت خانه های هوشمند، تمام اجزای
              ساختمان شما را هوشمند خواهد کرد با سابقه ای درخشان در طراحی و
              اجرای سیستم های هوشمند ساختمانی نظیر سیستم های مدیریت ساختمان
              (BMS) و خانه هوشمند مبتنی بر اینترنت اشیا، ساختمان شما را فارغ از
              سال ساخت، نوع کاربری و سیستم برق کشی آن هوشمند می نماید. خانه های
              هوشمند برای تمام ساختمان ها، برای هر سلیقه و بودجه و برای هر نوع
              از کاربری یک راهکار ویژه و سلوشن های متنوع و متعددی ارائه می کند.
              نور هوشمند، هوشمند سازی سیستم های تهویه، امنیت و ایمنی هوشمند،
              هوشمند سازی لوازم خانگی، پرده هوشمند، آبیاری هوشمند، سیستم های صوت
              و تصویر، برق و انرژی و … تنها بخشی از خدمات خانه های هوشمند برای
              ساختمان ها است. برای سفارش خانه هوشمند خود ما تماس بگیرید و یا فرم
              پیش سفارش هوشمندسازی موجود در پایین صفحه را تکمیل کنید. با استفاده
              از محصولات برترین برندهای جهان فعال در IoT و تجهیزات KNX و تلفیق
              آن با برنامه‌نویسی، معماری و الکترونیک، کنترل اجزای ساختمان شما با
              نرم افزارهای تلفن همراه، انواع دستیار صوتی نظیر گوگل، الکسا و
              سیری، ویجت و ریموت کنترل‌های تابعی و اتوماسیون در کنترل دستان
              شماست تا در هر لحظه بر عملکردهای ساختمان هوشمند خود نظارت و کنترل
              داشته باشید. انجام پروژه هوشمند سازی ساختمان ها و واحدهای اداری و
              تجاری در تهران، البرز، گیلان، مازندران، اصفهان، شیراز، تبریز، کیش
              و … قسمتی از نمونه کارهای ما است.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
